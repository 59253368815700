import { WrongIcon } from "@assets/icons/WrongIcon";
import tw from "twin.macro";

const Container = tw.div`h-screen flex flex-col items-center justify-center text-2xl md:text-3xl  text-gray-600 dark:text-gray-100 `;
const Content = tw.div`flex flex-col items-center gap-10`;
const Button = tw.button`w-full rounded-xl p-4 border-[1px] border-solid hover:bg-dark-gray text-xl`;
const Text = tw.h2`font-semibold`;

const ErrorFallback = () => {
  const handleReload = () => window.location.reload();

  return (
    <Container>
      <Content>
        <WrongIcon size={300} />
        <Text>Ooops, something went wrong</Text>
        <Button onClick={handleReload}>Refresh</Button>
      </Content>
    </Container>
  );
};

export default ErrorFallback;
