import { OTPInput, type SlotProps } from "input-otp";
import tw from "twin.macro";
const OPTInput = ({ onChange, error }: any) => {
  return (
    <OTPInput
      autoFocus
      onChange={onChange}
      maxLength={6}
      containerClassName="group flex items-center has-[:disabled]:opacity-30"
      render={({ slots }) => (
        <>
          <div className="flex w-full gap-2">
            {slots.slice(0, 6).map((slot, idx) => (
              <Slot key={idx} {...slot} error={error} />
            ))}
          </div>
        </>
      )}
    />
  );
};

export default OPTInput;

function Slot(props: SlotProps & { error: boolean }) {
  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      css={[
        tw`outline-1 border transition-all duration-100 flex items-center justify-center relative h-20 text-2xl flex-1 outline rounded`,
        props.isActive && tw`outline-2`,
        props.error && tw`text-red`,
      ]}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-[2px] h-8 bg-white" />
    </div>
  );
}
