import { checkPortability as apiCheckPortability } from "@api/index";
import { captureException } from "@sentry/react";
import { extractNumbers } from "../utils/index";
import { useCallback, useState } from "react";

const PHONE_LENGTH = 10; // e.g 7377121213
export const useCheckPortability = (data: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>();

  const checkPortability = useCallback(async (draftPhone: string) => {
    const phone = extractNumbers(draftPhone);
    if (phone.length < PHONE_LENGTH) {
      setSuccess(false);
      setError("");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const res = await apiCheckPortability({ phone });
      if (res.eligible) {
        setSuccess(true);
        setError("");
      } else {
        setSuccess(false);
        setError("unfortunately, your phone number is not eligible");
      }

      setLoading(false);
    } catch (error) {
      captureException(error, {
        level: "log",
        tags: {
          checkPortability: true,
        },
        user: {
          email: data.email || data.phone_number,
        },
        extra: {
          phone,
        },
      });
      setError("something went wrong, please try again");
      setSuccess(false);
      setLoading(false);
    }
  }, []);

  return { checkPortability, success, loading, error };
};
