import { createContext } from "react";

export interface SubscriptionFlowData {
  skipActivationStep: boolean;
  skipPortStep: boolean;
}

interface SubscriptionDataContextType {
  data: SubscriptionFlowData;
  addData: (data: any) => void;
  setGoBack: (fn: () => void) => void;
  navigation: {
    goBack: () => void;
  };
}
export const SubscriptionDataContext = createContext<SubscriptionDataContextType>({
  data: {
    skipActivationStep: false,
    skipPortStep: false,
  },
  addData: () => {},
  setGoBack: () => {},
  navigation: {
    goBack: () => null,
  },
});
