export const getFirstName = (name: string | null | undefined) => {
  return name ? name.split(" ")[0] : "";
};

export const generateRandomPassword = (length: number = 12): string => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }
  return password;
};

export const detectUserDevice = () => {
  try {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) return "ios";

    // Default to iOS if unknown
    return "ios";
  } catch (error) {
    return "ios";
  }
};
