import React from "react";
import { Spinner } from "./common/Spinner";

type Props = {
  onClick?: any;
  label?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
};

const Button: React.FC<Props> = ({ label, onClick, className, disabled, loading }) => {
  return (
    <button
      onClick={onClick}
      className={className}
      disabled={disabled || loading}
      style={{ opacity: disabled || loading ? 0.5 : 1 }}
    >
      {label}
      {loading && <Spinner />}
    </button>
  );
};

export default Button;
