import { useCallback, useEffect, useState } from "react";
import { getCustomerInfo } from "@api/index";
import { type IDevice } from "@components/widgets/YourDevicesWidget";
import { convertToDevices } from "@utils/formatters";
import { getFirstAvailableExpiryDate } from "@utils/date";
import { captureMessage } from "@sentry/react";
import { useAuth } from "@context/authContext";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "@constants/routes";

export enum VerificationType {
  EMAIL = "email",
  RECOVERY = "recovery phrase",
}
export interface CustomerInfo {
  lines: ILine[];
  verification: { type: VerificationType; is_verified: boolean };
}

export interface CustomerInfoData {
  loading: boolean;
  devices: IDevice[];
  upcomingPaymentDate: string;
  data?: CustomerInfo;
  error: any | string;
  fetchInfo: () => void;
}

export const useCustomerInfo = (): CustomerInfoData => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const { user, logout } = useAuth();
  const [data, setData] = useState<CustomerInfo>();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [upcomingPaymentDate, setUpcomingPaymentDate] = useState<string>("NA");

  const fetchInfo = useCallback(async () => {
    setLoading(true);

    try {
      const data = await getCustomerInfo();
      setDevices(convertToDevices(data.lines));
      setUpcomingPaymentDate(getFirstAvailableExpiryDate(data.lines));
      setData(data);
    } catch (err: any) {
      const msg = err?.response?.data?.message;
      if (msg === "no lines found by subscriber id") {
        captureMessage("Non customer sign in attempt", {
          level: "warning",
          user: {
            email: user?.email || user?.phoneNumber || undefined,
          },
        });
        logout();
        navigate(`/${NavRoutes.Login}?msg=wrng`, { replace: true });
      }

      setError(err);
    }

    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    upcomingPaymentDate,
    devices,
    data,
    error,
    fetchInfo,
  };
};
