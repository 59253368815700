import React from "react";
import tw from "twin.macro";
import defaultAvatar from "@assets/icons/AvatarIcon.svg";
import { type User as IUser } from "firebase/auth";
import { mapPhoneNumber } from "@utils/formatters";

const Container = tw.div`flex gap-3 pl-2 pt-3 pb-0 whitespace-nowrap`;
const Txt = tw.p`font-really text-sm leading-5 overflow-hidden text-ellipsis`;
const Name = tw(Txt)`font-bold`;
const Email = tw(Txt)`font-normal opacity-60`;
const Phone = tw(Txt)`text-xl leading-8`;
const TxtContainer = tw.div`flex flex-col justify-center w-[80%] sm:w-[90%]`;

type Props = {
  user: IUser;
};

const UserImage = tw.img`bg-white rounded-full w-[43px]`;
const User: React.FC<Props> = ({ user }) => {
  const renderBody = () => {
    if (user.phoneNumber) {
      return <Phone>{mapPhoneNumber(user.phoneNumber)}</Phone>;
    }

    return (
      <>
        {user.displayName && <Name>{user.displayName}</Name>}
        {user.email && <Email>{user.email}</Email>}
      </>
    );
  };

  return (
    <Container>
      <UserImage
        src={user.photoURL || defaultAvatar}
        alt="user avatar"
        referrerPolicy="no-referrer"
      />
      <TxtContainer>{renderBody()}</TxtContainer>
    </Container>
  );
};

export default User;
