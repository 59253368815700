import NotFoundIcon from "@assets/icons/NotFoundIcon";
import { NavRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import tw from "twin.macro";

const Container = tw.div`h-[80vh] sm:h-full flex w-full items-center justify-center flex-col gap-10`;
const Text = tw.h2`font-bold text-3xl md:text-6xl`;
const TwLink = tw(Link)`rounded-xl p-4 border-[1px] border-solid hover:bg-dark-gray`;

const NotFoundPage = () => {
  return (
    <Container>
      <NotFoundIcon size={300} />
      <Text>Page not found </Text>
      <TwLink to={NavRoutes.Dashboard}>Navigate to home</TwLink>
    </Container>
  );
};

export default NotFoundPage;
