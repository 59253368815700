import { LifebuoyIcon, BarChartIcon, DollarIcon, LockIcon } from "@assets/icons";
import { NavRoutes } from "constants/routes";
import tw from "twin.macro";
import Navigation from "./Navigation";

export interface INavItem {
  label: string;
  to: string;
  icon: JSX.Element;
}

const TOP_NAVS: INavItem[] = [
  {
    label: "dashboard",
    to: `/${NavRoutes.Dashboard}`,
    icon: <BarChartIcon />,
  },
  {
    label: "billing & lines",
    to: `/${NavRoutes.BillingDevices}`,
    icon: <DollarIcon />,
  },
  {
    label: "privacy & security",
    to: `/${NavRoutes.PrivacySecurity}`,
    icon: <LockIcon />,
  },
];

const BOTTOM_NAVS: INavItem[] = [
  {
    label: "support",
    to: `/${NavRoutes.Support}`,
    icon: <LifebuoyIcon />,
  },
];

const Container = tw.div`flex overflow-auto flex-col md:justify-between justify-center h-full md:pb-12`;

const SidebarNav = () => {
  return (
    <Container>
      <Navigation navs={TOP_NAVS} />
      <Navigation navs={BOTTOM_NAVS} />
    </Container>
  );
};

export default SidebarNav;
