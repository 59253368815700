import React, { type ReactElement, forwardRef } from "react";
import tw, { type TwStyle } from "twin.macro";
import {
  type FieldError,
  type Merge,
  type FieldErrorsImpl,
  type UseFormRegister,
} from "react-hook-form";

import AlertCircle from "@assets/icons/alert-circle.svg";

interface InputFieldProps {
  rootCss?: TwStyle | TwStyle[];
  name: string;
  label?: string | ReactElement;
  placeholder?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  pattern?: any;
  type?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  minLength?: number;
  maxLength?: number;
  max?: number;
  validate?: (value: string) => string;
  className?: string;
  disabled?: boolean;
  onPaste?: any;
  autoComplete?: "off" | "on";
}

const messages = {
  required: () => "this field is required",
  minLength: (field: string) => `Your ${field.toLowerCase()} is to short`,
  maxLength: (field: string) => `Your ${field.toLowerCase()} is too long`,
};

const Root = tw.div`w-full flex items-start flex-col`;
const InputContainer = tw.div`w-full relative`;
const Input = tw.input`w-full h-[61px] rounded border-[1.5px] pl-[16px] text-[18px] dark:bg-[#333333] dark:text-white border-2`;
const Label = tw.label`w-full text-[16px] mb-[12px]`;
const ErrorMessage = tw.div`w-full text-[13px] text-red mt-[3px]`;
const ErrorAlert = tw.div`absolute top-[calc(50% - 12px)] right-[20px]`;

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      name,
      register,
      required,
      placeholder,
      pattern = {},
      type = "text",
      error,
      value,
      onChange,
      maxLength,
      minLength,
      max,
      validate,
      className,
      rootCss,
      disabled,
      onPaste,
      autoComplete = "off",
    },
    ref,
  ) => {
    const inputProps = {
      required: required ? "this field is required." : "",
      pattern,
      validate,
    };

    const registerProps = register ? register(name, inputProps) : {};
    const combinedProps = { ...registerProps, onChange };

    return (
      <Root css={rootCss}>
        {label && (
          <Label
            htmlFor={name}
            className={`input-hide-arrows ${error ? "text-red" : "text-inherit"}`}
          >
            {label}
          </Label>
        )}
        <InputContainer>
          <Input
            ref={ref}
            type={type}
            id={name}
            placeholder={placeholder}
            autoComplete={autoComplete}
            className={`${
              error ? "border-red-500 pr-[44px]" : "border-black dark:border-white"
            } ${className}`}
            value={value}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            disabled={disabled}
            onPaste={onPaste}
            {...combinedProps}
          />
          {error && (
            <ErrorAlert>
              <img src={AlertCircle} />
            </ErrorAlert>
          )}
        </InputContainer>

        {error && (
          <ErrorMessage>
            {/* @ts-expect-error conflict with react-form types */}
            {error.message || messages[error.type]?.(label)}
          </ErrorMessage>
        )}
      </Root>
    );
  },
);
