import { type PaymentDetailsResponse } from "@api/index";
import { useCallback, useState } from "react";
import { getPaymentDetails } from "@api/index";
import { captureException, captureMessage } from "@sentry/react";

interface ReturnType {
  loading: boolean;
  validatePaymentDetails: (data: any) => Promise<any>;
  paymentData: PaymentDetailsResponse;
  handleError: HandleErrorFunction;
  unhandledError: any | string;
}

const INVALID_PROMO_TXTS = ["invalid promo code", "coupon not available", "invalid coupon code"];

const INVALID_EMAIL_TXTS = ["Field validation for 'Email'"];
const INVALID_ZIP_TXTS = ["invalid zip code", "Field validation for 'ZipCode"];

interface ErrorObject {
  message?: string;
  response?: {
    data?: {
      message?: string;
    };
  };
}

export type HandleErrorFunction = (err: ErrorObject | string) => boolean; // true - need to be reported, false - don't report

export const useValidatePaymentDetails = (setError: any, state: any): ReturnType => {
  const data = state.context;
  const [loading, setLoading] = useState<boolean>(false);
  const [unhandledError, setUnhandledError] = useState<string>();
  const [paymentData, setPaymentData] = useState<PaymentDetailsResponse>({
    sub_total: 0,
    total: 0,
    discount: 0,
    tax: 0,
  });

  const handleError: HandleErrorFunction = useCallback(
    (err) => {
      const message =
        typeof err === "string"
          ? err
          : err?.response?.data?.message || err?.message || "Unknown error";

      setPaymentData((paymentData: any) => ({
        ...paymentData,
        discount: 0,
      }));

      if (INVALID_PROMO_TXTS.some((errorMsg) => message.includes(errorMsg))) {
        setError("promocode", {
          type: "custom",
          message: "invalid promo code",
        });

        return false;
      }

      if (INVALID_ZIP_TXTS.some((errorMsg) => message.includes(errorMsg))) {
        // TODO: Temporary fix, this should be done in BE, we never should be getting zip code error
        state.send({ type: "SET_ZIPCODE", zipcode: "78701" });
        validatePaymentDetails({ promocode: data.promocode, zipcode: "78701" });

        captureMessage("Invalid Zip Code", {
          level: "warning",
          tags: {
            invalidZipCode: true,
          },
          user: { email: data.email || data.phone_number },
          extra: {
            payload: { zipcode: data.zipcode },
          },
        });

        // setError("zipcode", { type: "custom", message: "invalid zip code" });
        return false;
      }

      if (INVALID_EMAIL_TXTS.some((errorMsg) => message.includes(errorMsg))) {
        setError("phoneOrEmail", {
          type: "custom",
          message: "invalid email address",
        });
        return false;
      }

      setUnhandledError(message);
      return true;
    },
    [setPaymentData],
  );

  const validatePaymentDetails = useCallback(
    async ({ promocode, zipcode, enrollment_id }: any) => {
      setLoading(true);

      const payload = {
        coupon_code: promocode,
        enrollment_id,
        items: [
          {
            // hardcoded to limitless
            plan_code: "1",
            quantity: 1,
          },
        ],
        zip_code: zipcode,
      };

      try {
        const paymentData = await getPaymentDetails(payload);
        setPaymentData(paymentData);

        if (paymentData.message) {
          throw new Error(paymentData.message);
        }
      } catch (err: any) {
        const needReport = handleError(err);

        if (needReport) {
          captureException(err, {
            level: "error",
            tags: {
              validatePayment: true,
            },
            user: {
              email: data.email || data.phone_number,
            },
            extra: {
              payload: JSON.stringify(payload),
            },
          });
        }
      }

      setLoading(false);
    },
    [setPaymentData, handleError, data.selectedPlan, data.numberOfLines],
  );

  return {
    loading,
    validatePaymentDetails,
    paymentData,
    handleError,
    unhandledError,
  };
};
