import { setup, assign } from "xstate";

export const newLineMachine = setup({
  guards: {},
  actions: {},
}).createMachine({
  id: "newLineFlow",
  initial: "idle",
  context: {
    phoneBrand: null,
    phoneModel: null,
    zipcode: null,
    isClutch: false,
    isLimitless: true,
    portNumber: null,
    lines: {
      plan: {},
    },
  },
  states: {
    idle: {
      on: {
        INITIALIZE: {
          target: "newLine",
          actions: assign(({ event }) => ({
            lines: event.selectedPlan,
          })),
        },
      },
    },
    newLine: {
      id: "newLineMachine",
      initial: "porting",
      states: {
        porting: {
          on: {
            NEXT: {
              target: "checkout",
              actions: assign(({ event }) => ({
                zipcode: event.zipcode,
                portNumber: event.phoneNumber,
              })),
            },
          },
        },
        checkout: {
          on: {
            NEXT: "success",
            BACK: "porting",
            SET_ZIPCODE: {
              actions: assign(({ event }) => ({
                zipcode: event.zipcode,
              })),
            },
            SET_PROMOCODE: {
              actions: assign(({ event }) => ({
                promocode: event.promocode,
              })),
            },
          },
        },
        success: {
          on: {
            BACK: "checkout",
          },
        },
      },
    },
    complete: {},
  },
});
