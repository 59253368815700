import React, { useCallback, useEffect } from "react";
import tw from "twin.macro";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { updateUrlWithState, newLineRoutes } from "@utils/navigation";
import { newLineMachine } from "../state-machine/new-line-machine";
import { Stepper } from "@components/subscription/Stepper";
import { SubscriptionLayout } from "@components/SubscriptionLayout";
import withCustomerInfo from "../hocs/withCustomerInfo";
import PortNumber from "./PortNumber";
import Checkout from "./Checkout";
import Success from "./Success";

const LIMITLESS_PLAN_CODE = 1;

const Container = tw.div`min-h-[100%] px-[5%]`;
const Content = tw.div`max-w-1200 mx-auto`;

const NewLine: React.FC<any> = ({ customerInfo }) => {
  const [state, send] = useMachine(newLineMachine);
  const navigate = useNavigate();

  const onNextStep = useCallback((payload: any) => {
    send({ type: "NEXT", ...payload });
  }, []);

  const onBack = useCallback(() => {
    // @ts-ignore
    if (state?.value?.newLine === "porting") {
      navigate("/");
    }
    send({ type: "BACK" });
  }, [state]);

  useEffect(() => {
    send({
      type: "INITIALIZE",
      selectedPlan: {
        code: LIMITLESS_PLAN_CODE,
      },
    });
  }, []);

  useEffect(() => {
    updateUrlWithState(state.value, navigate, newLineRoutes);
  }, [state.value, location.pathname]);

  return (
    <div className="dark">
      <SubscriptionLayout darkMode={true} goBack={onBack}>
        <Stepper darkMode />
        <Container>
          <Content>
            <Routes>
              <Route
                path="porting"
                element={
                  <PortNumber state={state} onNextStep={onNextStep} customerInfo={customerInfo} />
                }
              />
              <Route
                path="checkout"
                element={
                  <Checkout
                    onNextStep={onNextStep}
                    state={state}
                    goBack={onBack}
                    send={send}
                    customerInfo={customerInfo}
                  />
                }
              />
              <Route path="success" element={<Success />} />
            </Routes>
          </Content>
        </Container>
      </SubscriptionLayout>
    </div>
  );
};

export default withCustomerInfo(NewLine);
