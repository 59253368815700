import { useEffect, useRef } from "react";
import { extractNumbers } from "../utils/index";
import { useCallback, useState } from "react";
import { captureException } from "@sentry/react";
import { type HandleErrorFunction } from "./useValidatePaymentDetails";
import { addNewLine, getNewLineStatus } from "@api/index";

export const useCheckout = (handleError: HandleErrorFunction, onNextStep: any) => {
  const [loading, setLoading] = useState(false);
  const [data, _] = useState();
  const [newLineId, setNewLineId] = useState("");
  const [isNewLineComplete, setNewLineIsComplete] = useState(false);
  const attempts = useRef(0);
  const interval: any = useRef();

  useEffect(() => {
    if (newLineId) {
      interval.current = setInterval(async () => {
        if (attempts.current >= 3) {
          clearInterval(interval.current);
          return;
        }

        const status = await getNewLineStatus(newLineId);

        if (status.is_complete) {
          setNewLineIsComplete(true);
          clearInterval(interval.current);
          setLoading(false);
          onNextStep();
        }

        attempts.current = attempts.current + 1;
      }, 3000);
    }
  }, [newLineId]);

  useEffect(() => {
    return clearInterval(interval.current);
  }, []);

  const postCheckout = useCallback(async (requestData: any) => {
    setLoading(true);

    let payload: any = {};
    try {
      const data = requestData;

      payload = {
        coupon_code: data?.promocode,
        external_id: data?.external_id,
        is_esim: true,
        number_to_port: extractNumbers(data?.portNumber),
        plan_code: String(data?.lines?.code),
        price: data?.lines.price,
        zip_code: data?.zipcode,
      };

      const res = await addNewLine(payload);
      setNewLineId(res.external_id);
    } catch (error: any) {
      setLoading(false);
      const needReport = handleError(error);

      if (needReport) {
        captureException(error, {
          level: "error",
          tags: {
            stipe: true,
          },
          extra: {
            payload: JSON.stringify(payload),
          },
          user: {
            email: payload.email || payload.phone_number,
          },
        });
      }
    }
  }, []);

  return { postCheckout, data, loading, isNewLineComplete };
};
