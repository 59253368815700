import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const PinIcon: React.FC<Props> = ({ size = 16, color = "currentColor" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58447 10.411L1.81323 14.1822M7.79626 4.42796L6.75567 5.46855C6.67079 5.55343 6.62834 5.59587 6.57999 5.6296C6.53707 5.65953 6.49078 5.68431 6.44206 5.70341C6.38718 5.72494 6.32832 5.73671 6.21061 5.76025L3.76764 6.24885C3.13277 6.37582 2.81533 6.43931 2.66683 6.60668C2.53745 6.75249 2.47837 6.94761 2.50514 7.1407C2.53586 7.36233 2.76477 7.59124 3.22258 8.04905L7.9464 12.7729C8.40422 13.2307 8.63312 13.4596 8.85476 13.4903C9.04784 13.5171 9.24297 13.458 9.38878 13.3286C9.55614 13.1801 9.61963 12.8627 9.7466 12.2278L10.2352 9.78484C10.2587 9.66713 10.2705 9.60828 10.292 9.55339C10.3111 9.50468 10.3359 9.45838 10.3659 9.41546C10.3996 9.36711 10.442 9.32467 10.5269 9.23979L11.5675 8.19919C11.6218 8.14492 11.6489 8.11779 11.6787 8.0941C11.7052 8.07305 11.7333 8.05405 11.7627 8.03727C11.7957 8.01837 11.831 8.00325 11.9016 7.97302L13.5645 7.26035C14.0496 7.05243 14.2922 6.94848 14.4024 6.78049C14.4987 6.63358 14.5332 6.45458 14.4983 6.2824C14.4584 6.0855 14.2718 5.89889 13.8985 5.52568L10.4698 2.09692C10.0966 1.7237 9.90995 1.53709 9.71305 1.49718C9.54087 1.46227 9.36187 1.49675 9.21497 1.5931C9.04698 1.70329 8.94302 1.94585 8.73511 2.43099L8.02243 4.09389C7.9922 4.16444 7.97708 4.19971 7.95819 4.23278C7.9414 4.26217 7.9224 4.29023 7.90136 4.31672C7.87767 4.34655 7.85053 4.37369 7.79626 4.42796Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
