import React, { useCallback } from "react";
import tw from "twin.macro";
import { toCurrency } from "@utils/currency";

interface CheckoutSummaryProps {
  isPaid?: boolean;
  paymentData?: any;
  loading: boolean;
  selectedPlan?: Plan;
  numberOfLines?: number;
}

const Container = tw.div`w-full border border-black shadow-really bg-[#1A1A1A]`;
const Row = tw.div`p-[20px] border-b border-black min-h-[60px] font-normal`;
const Text = tw.div`text-[20px] font-normal`;
const PlanContainer = tw.div`flex justify-between items-center`;
const PlanItem = tw.div`flex`;

export const CheckoutSummary: React.FC<CheckoutSummaryProps> = ({ paymentData = {} }) => {
  const renderDiscountRow = useCallback((): React.ReactNode => {
    if (paymentData?.discount) {
      return (
        <Row>
          <PlanContainer>
            <PlanItem>
              <Text className="text-[#D92D20]">promo discount</Text>
            </PlanItem>
            <PlanItem>
              <Text className="text-[#D92D20]">{toCurrency(paymentData?.discount)}</Text>
            </PlanItem>
          </PlanContainer>
        </Row>
      );
    }
    return <></>;
  }, [paymentData?.discount]);

  return (
    <Container>
      {renderDiscountRow()}
      <Row>
        <PlanContainer>
          <PlanItem>
            <Text>instant eSIM activation</Text>
          </PlanItem>
          <PlanItem>
            <Text>free</Text>
          </PlanItem>
        </PlanContainer>
      </Row>
      <Row>
        <PlanContainer>
          <PlanItem>
            <Text>full privacy & encryption</Text>
          </PlanItem>
          <PlanItem>
            <Text>free</Text>
          </PlanItem>
        </PlanContainer>
      </Row>
      <Row>
        <PlanContainer>
          <PlanItem>
            <Text>REALLY membership fees</Text>
          </PlanItem>
          <PlanItem>
            <Text>free</Text>
          </PlanItem>
        </PlanContainer>
      </Row>
    </Container>
  );
};
