import React from "react";
import tw from "twin.macro";
const isDevelopment = import.meta.env.DEV;

const Title = tw.h1`text-red`;
const Container = tw.div`flex flex-col gap-1`;
const ErrorTxt = tw.pre`whitespace-normal overflow-hidden sm:max-w-[300px] md:max-w-[500px]`;
interface Props {
  error: any;
}
const ErrorMessage: React.FC<Props> = ({ error }) => {
  if (!error) return null;

  return (
    <Container>
      <Title>Oops! An unexpected error occurred.</Title>
      {isDevelopment && <ErrorTxt>{JSON.stringify(error, null, 2)}</ErrorTxt>}
    </Container>
  );
};

export default ErrorMessage;
