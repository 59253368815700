import React from "react";

type Props = {
  color?: string;
};

export const CircleExclamation: React.FC<Props> = ({ color = "none" }) => {
  return (
    <svg width="44" height="43" viewBox="0 0 44 43" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M8 21.5C8 13.768 14.268 7.5 22 7.5C29.732 7.5 36 13.768 36 21.5C36 29.232 29.732 35.5 22 35.5C14.268 35.5 8 29.232 8 21.5Z"
          stroke="#7151F0"
          strokeWidth="2"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M1.75 21.5C1.75 10.3162 10.8162 1.25 22 1.25C33.1838 1.25 42.25 10.3162 42.25 21.5C42.25 32.6838 33.1838 41.75 22 41.75C10.8162 41.75 1.75 32.6838 1.75 21.5Z"
          stroke="#7151F0"
          strokeWidth="2"
        />
      </g>
      <path
        d="M21.9998 24.8332V21.4998M21.9998 18.1665H22.0082M30.3332 21.4998C30.3332 26.1022 26.6022 29.8332 21.9998 29.8332C17.3975 29.8332 13.6665 26.1022 13.6665 21.4998C13.6665 16.8975 17.3975 13.1665 21.9998 13.1665C26.6022 13.1665 30.3332 16.8975 30.3332 21.4998Z"
        stroke="#7151F0"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
