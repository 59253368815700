import React, { type ReactNode } from "react";
import { Modal as ResponsiveModal, type ModalProps } from "react-responsive-modal";
import tw from "twin.macro";
import { CloseIcon } from "@assets/icons";

interface Props extends ModalProps {
  Component: ReactNode;
  darkMode?: boolean;
}

const ModalContainer = tw.div`m-2.5 mt-10 w-fit max-w-[540px] break-words relative`;

export const Modal: React.FC<Props> = ({ Component, open, darkMode, ...rest }) => {
  return (
    <ResponsiveModal
      {...rest}
      open={open}
      center
      closeIcon={<CloseIcon />}
      styles={{
        modal: {
          borderRadius: 4,
          padding: 20,
          backgroundColor: darkMode ? "#333333" : "",
          color: darkMode ? "white" : "",
        },
      }}
    >
      <ModalContainer>{Component}</ModalContainer>
    </ResponsiveModal>
  );
};
