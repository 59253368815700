import React, { useState, useCallback, useRef, useEffect, Suspense, lazy } from "react";
import tw from "twin.macro";
import { QuestionSwitch } from "../components/commons/QuestionSwitch";
import { TelephoneInput } from "../components/commons/TelephoneInput";
import { useCheckPortability } from "../hooks/useCheckPortability";
import { CircleExclamation } from "@assets/icons/CircleExclamation";
import { preloadOnIdle } from "../utils/index";
import { Modal } from "@components/subscription/Modal";
import { getCustomerZipcode } from "@utils/customer-info";
import { CustomerInfo } from "@hooks/useCustomerInfo";

// Lazy load
const BringYourNumber = lazy(() => import("../components/modals/BringYourNumber"));

interface PortNumberProps {
  state: any;
  onNextStep: any;
  customerInfo: CustomerInfo;
}

const Content = tw.div`pt-[20px] lg:pt-[100px] pb-[150px]`;
const Container = tw.div`animate-fade-in`;
const Title = tw.div`text-lg md:text-xl font-medium`;
const IdontKnowContainer = tw.div`w-full lg:w-[486px] flex my-[20px]`;
const IdontKnowButton = tw.button`text-white underline`;
const IconContainer = tw.div`rounded-full border-[#afd9cb] p-1`;
const NoZipCodeTitle = tw.div`text-[27px] font-[300] leading-none`;
const CompatibleContainer = tw.div`rounded-xl bg-[#1A1A1A] p-3 flex items-center w-fit lg:w-[600px]`;
const Button = tw.div`cursor-pointer flex py-2.5 px-3.5 items-center justify-center gap-1 w-full border-2 border-white text-white lg:w-[300px] font-[400] h-[65px] lg:w-[406px] mt-[40px] text-[21px]`;
const Link = tw.a`underline text-[14px]`;
const TitleContainer = tw.div`flex flex-row items-center gap-3`;

const PortNumber: React.FC<PortNumberProps> = ({ state, onNextStep, customerInfo }) => {
  const isClutchPlan = state.context.isClutchPlan;
  const [open, setOpen] = useState(false);

  const [showPhoneInput, setShowPhoneInput] = useState<null | boolean>(false);
  const [showZipcode, setShowZipcode] = useState<null | boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const { checkPortability, error, loading, success } = useCheckPortability({
    phone_number: phoneNumber,
  });
  const telRef = useRef<HTMLInputElement>(null);

  const handlePhoneChange = async (newValue: string) => {
    setPhoneNumber(newValue);
    checkPortability(newValue);
  };

  const onCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const onSaveClick = async () => {
    onNextStep({ phoneNumber, zipcode: getCustomerZipcode(customerInfo) });
  };

  useEffect(() => {
    if (showPhoneInput && telRef.current) {
      telRef.current.focus();
      telRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showPhoneInput]);

  useEffect(() => {
    preloadOnIdle(() => {
      import("../components/modals/BringYourNumber");
    });
  }, []);

  return (
    <Content>
      <Container>
        <CompatibleContainer>
          <TitleContainer>
            <IconContainer>
              <CircleExclamation />
            </IconContainer>
            <div className="flex flex-col">
              <Title>make sure this new phone is eSIM compatible.</Title>
              <Link href="https://www.really.com/post/how-to-check-if-your-phone-is-esim-compatible">
                you can check here
              </Link>
            </div>
          </TitleContainer>
        </CompatibleContainer>
        {!isClutchPlan && (
          <>
            <QuestionSwitch
              title="want to transfer your current phone number?"
              selected={!!showPhoneInput}
              onClick={(val: boolean) => {
                setShowPhoneInput(val);
                setShowZipcode(!val);
              }}
            />
            <IdontKnowContainer>
              <IdontKnowButton onClick={() => setOpen(true)}>i’m not sure.</IdontKnowButton>
            </IdontKnowContainer>
          </>
        )}
        {showPhoneInput && (
          <div>
            <TelephoneInput
              ref={telRef}
              title="what's your phone number?"
              onChange={handlePhoneChange}
              value={phoneNumber}
              loading={loading}
              errorMsg={error}
              successMsg={success && "congrats, your phone number is eligible"}
            />
            <div className="text-16 mt-2">
              please note that once your phone number is activated with REALLY, your account with
              your previous carrier will be closed.
            </div>
          </div>
        )}
        {showZipcode && (
          <NoZipCodeTitle>
            not a problem, we’ll give you a new number <br />
            once your SIM is activated.
          </NoZipCodeTitle>
        )}
        {(success || showZipcode) && <Button onClick={onSaveClick}>save & continue</Button>}
        {open && (
          <Suspense fallback={<></>}>
            <Modal
              Component={<BringYourNumber />}
              onClose={onCloseModal}
              open={true}
              darkMode={true}
            />
          </Suspense>
        )}
      </Container>
    </Content>
  );
};

export default PortNumber;
