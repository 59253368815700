import React, { forwardRef } from "react";
import tw from "twin.macro";
import { formatPhoneNumber } from "@utils/formatters";
import { Spinner } from "@components/common/Spinner";

interface TelephoneInputProps {
  title: string;
  value: string;
  onChange: (e: string) => void;
  loading?: boolean;
  errorMsg?: string | boolean;
  successMsg?: string | boolean;
}

const Title = tw.div`text-[27px] font-normal leading-none mb-[20px]`;
const Container = tw.div`w-full mt-[35px] flex flex-col`;
const RelativeContainer = tw.div`relative mb-1`;
const SpinnerContainer = tw.div`absolute top-[20px] left-[200px]`;
const Error = tw.p`text-red`;
const Success = tw.p`text-black`;

const TelephoneInput = forwardRef<HTMLInputElement, TelephoneInputProps>(
  ({ title, value, onChange, errorMsg, successMsg, loading }, ref) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = formatPhoneNumber(e.target.value);
      onChange(newValue);
    };

    return (
      <Container>
        <Title>{title}</Title>
        <RelativeContainer>
          <input
            ref={ref}
            type="tel"
            value={value}
            onChange={handleInputChange}
            placeholder="(512) 555-1234"
            className="h-[69px] w-full lg:w-[330px] pl-[20px] text-[24px] font-normal bg-[#333333] text-white border-2 border-white"
          />
          {loading && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </RelativeContainer>
        {errorMsg && <Error>{errorMsg}</Error>}
        {successMsg && <Success>{successMsg}</Success>}
      </Container>
    );
  },
);

TelephoneInput.displayName = "TelephoneInput";

export { TelephoneInput };
