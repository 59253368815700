import React, { useState } from "react";
import tw from "twin.macro";
import { Button } from "@components/subscription/Button";

interface QuestionProps {
  onClick: (arg: any) => void;
  selected: boolean;
  title: string;
  subtitle?: string;
  ignoreDirty?: boolean;
}

const QuestionContainer = tw.div`flex flex-col`;
const QuestionTitle = tw.div`text-[36px] font-[300] leading-none w-full lg:w-[420px] mb-[20px]`;
const QuestionSubtitle = tw.p`text-[20px] font-[300] mb-[20px] text-[#656565]`;
const QuestionsButtonContainer = tw.div`flex flex-col w-full lg:w-[730px] gap-4`;

const getButtonType = (selected: boolean, dirty: boolean): any => {
  if (!dirty) return "active";

  return selected ? "active" : "disabled";
};

export const QuestionSwitch: React.FC<QuestionProps> = ({
  onClick,
  selected,
  title,
  subtitle = "",
  ignoreDirty = false,
}: QuestionProps) => {
  const [dirty, setDirty] = useState(ignoreDirty);

  const handleClick = (answer: boolean): void => {
    setDirty(true);
    onClick(answer);
  };

  return (
    <QuestionContainer className="mt-[35px]">
      <QuestionTitle>{title}</QuestionTitle>
      <QuestionSubtitle>{subtitle}</QuestionSubtitle>
      <QuestionsButtonContainer>
        <Button
          title="yes, bring my number"
          onClick={() => handleClick(true)}
          type={getButtonType(selected, dirty)}
          className="!w-fit px-[64px] py-[32px]"
        />
        <Button
          title="no, give me a new number"
          onClick={() => handleClick(false)}
          type={getButtonType(!selected, dirty)}
          className="!w-fit px-[64px] py-[32px]"
        />
      </QuestionsButtonContainer>
    </QuestionContainer>
  );
};
