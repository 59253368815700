import { TimerIcon } from "@assets/icons";
import tw from "twin.macro";

const Container = tw.div`flex h-[80vh] sm:h-full w-full items-center justify-center flex-col gap-10`;
const Text = tw.h2`text-2xl md:text-4xl lowercase`;

const ComingSoon = () => {
  return (
    <Container>
      <TimerIcon size={75} />
      <Text>Coming soon !</Text>
    </Container>
  );
};

export default ComingSoon;
