import { ForgotPassword } from "@really5g/ui-components";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "@context/authContext";
import { NavRoutes } from "constants/routes";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { resetPassword, user } = useAuth();

  const handleResetPassword = (data: { email: string }) => {
    resetPassword(data);
    alert(
      "A link to reset your password has been sent. Please check your email for further instructions.",
    );
    navigate(`/${NavRoutes.Login}`);
  };

  const onGoBack = () => navigate(`/${NavRoutes.Login}`);
  if (user) return <Navigate to={`/${NavRoutes.Dashboard}`} />;

  return <ForgotPassword onSubmit={handleResetPassword} footerOptions={{ onGoBack }} />;
};

export default ForgotPasswordPage;
