import React from "react";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";
import { PinIcon } from "@assets/icons/PinIcon";

interface SuccessProps {}

const Content = tw.div`pt-[20px] lg:pt-[100px] pb-[150px]`;
const Container = tw.div`animate-fade-in`;
const Title = tw.div`text-[48px] font-light leading-none mb-[30px]`;
const Paragraph = tw.div`text-[32px] leading-none mb-[20px] w-full`;
const ButtonContainer = tw.div`w-full lg:w-[300px] mt-[48px]`;
const FlexContainer = tw.div`flex flex-col lg:flex-row justify-center items-start lg:gap-[72px] gap-[40px] mb-[40px]`;
const FlexItem = tw.div`flex flex-col flex-1`;
const GrayCard = tw.div`w-full bg-[rgba(217, 217, 217, 0.20)] p-[32px] font-[400]`;
const CardTitle = tw.div`text-2xl font-normal my-7 leading-6`;
const CardList = tw.ol`[&>li]:my-2 text-[16px] font-normal [padding-inline-start: 20px] list-decimal`;
const CardListLatin = tw(CardList)`[list-style: lower-latin]`;
const CardCircle = tw.div`rounded-full w-[56px] h-[56px] bg-[rgba(217, 217, 217, 0.70)] mb-[10px] flex justify-center items-center`;
const Button = tw.button`bg-white text-black rounded py-[24px] px-[40px] text-[24px]`;

const Success: React.FC<SuccessProps> = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <Container>
        <FlexContainer>
          <FlexItem>
            <Title>you’re almost done!</Title>
            <Paragraph>
              in a few moments, you’ll be able to click below to complete your activation steps.
            </Paragraph>
            <ButtonContainer>
              <Button onClick={() => navigate("/activation")}>activate your esim</Button>
            </ButtonContainer>
          </FlexItem>
          <FlexItem>
            <GrayCard>
              <CardCircle>
                <PinIcon size={28} />
              </CardCircle>
              <CardTitle>things to know for activation:</CardTitle>
              <CardList>
                <li>make sure your phone is connected to wifi</li>
                <li>if you're transferring your phone number, make sure you:</li>
                <CardListLatin>
                  <li>have your existing carrier account number</li>
                  <li>request a transfer PIN from your existing carrier</li>
                </CardListLatin>
                <li>
                  make sure your phone is not locked by your carrier before scanning the activation
                  QR code
                </li>
              </CardList>
            </GrayCard>
          </FlexItem>
        </FlexContainer>
      </Container>
    </Content>
  );
};

export default Success;
