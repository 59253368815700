import { type User } from "firebase/auth";

// e.g +17372373334 to (737) 237-3334
const formatPhoneNumber = (phone: string) => {
  if (!phone) return "Unknown";
  return `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8)}`;
};

export const setIdentity = (user: User) => {
  const intervalId = setInterval(() => {
    if (window.FS) {
      const properties: any = {
        displayName: user.email || formatPhoneNumber(user.phoneNumber!),
      };

      if (user.email) properties.email = user.email;
      if (user.phoneNumber) properties.phone = formatPhoneNumber(user.phoneNumber);

      window.FS("setProperties", {
        type: "user",
        properties,
      });

      // Clear the interval once executed
      clearInterval(intervalId);
    }
  }, 1_000); // Check every second
};
