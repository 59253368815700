const activationRoutes: Record<string, string> = {
  optIn: "opt-in",
  recoveryPhrase: "recovery-phrase",
  verifyPhrase: "verify-phrase",
  emailSent: "email-sent",
  verifySuccess: "verify-success",
  unlockPhone: "unlock-phone",
  wifiConnection: "wifi-connection",
  wifiCalling: "wifi-calling",
  portNumber: "port-number",
  simActivation: "sim-activation",
  simInstallation: "sim-installation",
  deleteSim: "delete-old-sim",
  updateMessageApp: "update-message-app",
  facetimeConfig: "facetime-config",
  enableVoicemail: "enable-voicemail",
  restartPhone: "restart-phone",
  enableData: "enable-data",
  enableRoaming: "enable-roaming",
  complete: "success",
};

export const newLineRoutes: Record<string, string> = {
  deviceCompatibility: "device",
  porting: "porting",
  checkout: "checkout",
  success: "success",
};

export const getPathFromState = (path: string | object) => {
  if (typeof path === "string") return path;
  if (typeof path === "object" && path !== null) return Object.values(path)[0];

  return "";
};

// Function to update the URL with the current state and query parameters (if any)
export const updateUrlWithState = (
  currentState: string | Record<string, string>,
  navigate: (url: string) => void,
  routes?: any,
) => {
  const routesData = routes || activationRoutes;
  const routePath = routesData[getPathFromState(currentState)]; // Get the route based on the state

  if (routePath) {
    const finalRoute = window.location.search ? `${routePath}${window.location.search}` : routePath;
    // Get the current path and query string
    const currentUrl = `${window.location.pathname}${window.location.search}`;

    // Only navigate if the final route is different from the current URL
    if (!currentUrl.includes(finalRoute)) navigate(finalRoute);
  }
};

export const getQueryParam = (param: string): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(param);
};

export const hasInactiveLine = (line: ILine) => !line.activated;
