import { type IDevice } from "@components/widgets/YourDevicesWidget";

// from 5554447373 to (555) 444-7373
export const formatPhoneNumber: (input: string) => string = (input) => {
  const digits = input.replace(/\D/g, "");
  if (digits.length < 4) return digits;
  if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;

  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

// from +15554447373 to 555-444-7373
export const mapPhoneNumber = (phoneNumber: string) => {
  const localNumber = phoneNumber.slice(-10);
  return `${localNumber.slice(0, 3)}-${localNumber.slice(3, 6)}-${localNumber.slice(6)}`;
};

export const getPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return;

  return phoneNumber === "0" ? "N/A" : mapPhoneNumber(phoneNumber);
};

export const getNumberOrdinalValue = (index: number): string => {
  const ordinals: { [key: number | string]: string } = {
    0: "first",
    1: "second",
    2: "third",
    3: "fourth",
    4: "fifth",
    5: "sixth",
    6: "seventh",
    7: "eighth",
    8: "ninth",
    9: "tenth",
  };

  return ordinals[index];
};

export function convertToDevices(lines: ILine[]): IDevice[] {
  const formattedData = lines.map((line) => {
    return {
      numbers: line.plan.name === "REALLY Clutch" ? "" : formatPhoneNumber(line.phone_number),
      model: formatModel(line.phone_model),
      plan: line.plan.name,
      phoneQr: line.qr_code,
    };
  });

  return formattedData;
}

export function formatModel(phoneModel: string) {
  return phoneModel
    .split("_")
    .map((word) => {
      if (word === "iphone") {
        return "iPhone";
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export const toE164 = (phoneNumber: string, countryCode = "1") => {
  const digits = phoneNumber.replace(/\D/g, "");

  // Convert to E.164 format
  return `+${countryCode}${digits}`;
};
