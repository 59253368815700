import React, { useEffect } from "react";
import tw from "twin.macro";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-router-dom";
import { InputField } from "@components/subscription/InputField";
import { Button } from "@components/subscription/Button";
import { CheckoutSummary } from "../components/commons/CheckoutSummary";
import { Checkbox } from "@components/subscription/Checkbox";
import { useCheckout } from "../hooks/useCheckout";
import { useValidatePaymentDetails } from "../hooks/useValidatePaymentDetails";
import { CircleExclamation } from "@assets/icons/CircleExclamation";
import ErrorMessage from "../components/commons/ErrorMessage";
import { toCurrency } from "@utils/currency";
import { Spinner } from "@components/common/Spinner";
import { CustomerInfo } from "@hooks/useCustomerInfo";
import { getPrimaryEnrollmentId } from "@utils/customer-info";

const Container = tw.div`mx-[5%] animate-fade-in flex justify-center flex-col lg:flex-row mt-[20px] gap-10 lg:gap-[60px] max-w-[1140px]`;
const FlexItem = tw.div`flex flex-col w-full lg:w-[780px]`;
const SubmitBtnContainer = tw.div`w-full mt-[20px] mb-[20px]`;
const Title = tw.div`text-[34px] font-light leading-none mb-[30px]`;
const PromoCodeContainer = tw.div`lg:w-[65%] w-full flex justify-start items-end mr-auto mt-[30px] gap-[10px]`;
const Link = tw.a`underline`;
const Label = tw.div`!text-[15px]`;
const Total = tw.div`flex justify-between mt-[20px] flex-col`;
const TotalRow = tw.div`flex justify-between mb-[12px]`;
const TotalTitle = tw.div`text-[36px]`;
const TotalSubtitle = tw.div`text-[26px] font-[500]`;
const Separator = tw.div`border border-black mb-[12px]`;
const BillingMessage = tw.div`flex items-center text-[#868686] justify-center`;
const Content = tw.div`pt-[20px] pb-[150px]`;
const PromoControl = tw.div`flex flex-col`;
const CompatibleContainer = tw.div`rounded-xl bg-[#1A1A1A] p-3 flex items-center w-full mb-[40px]`;
const TitleContainer = tw.div`flex flex-row items-center gap-3`;
const IconContainer = tw.div`rounded-full border-[#afd9cb] p-1`;
const TitleBox = tw.div`text-lg md:text-xl font-medium`;
const AutoPlayContainer = tw.div`w-full mb-[20px]`;
const AutoPlayParagraph = tw.p`text-[18px] font-normal mb-[20px]`;

interface FormData {
  phoneOrEmail: string;
  terms: boolean;
  promocode: string;
  zipcode: string;
}

interface Props {
  state: any;
  goBack: any;
  send: any;
  onNextStep: any;
  customerInfo: CustomerInfo;
}

const Checkout: React.FC<Props> = ({ state, send, onNextStep, customerInfo }: Props) => {
  const data = state.context;
  const primaryEnrollmentId = getPrimaryEnrollmentId(customerInfo);

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      phoneOrEmail: data.email || data.phone_number,
      terms: data.terms || false,
      promocode: data.promocode,
      zipcode: data.zipcode,
    },
  });

  const {
    loading: paymentLoading,
    paymentData,
    validatePaymentDetails,
    handleError,
    unhandledError,
  } = useValidatePaymentDetails(setError, state);

  const { postCheckout, loading: checkoutLoading } = useCheckout(handleError, onNextStep);

  const checkoutDisabled = paymentLoading || !isValid;

  const onSubmit = async (formData: FormData) => {
    const payload = {
      ...formData,
      ...data,
    };

    postCheckout(payload);
  };

  useEffect(() => {
    validatePaymentDetails({
      ...getValues(),
      enrollment_id: primaryEnrollmentId,
    });
  }, []);

  return (
    <Content>
      <Form className="flex justify-center pb-10 transition-opacity duration-500">
        <Container>
          <FlexItem>
            <CompatibleContainer>
              <TitleContainer>
                <IconContainer>
                  <CircleExclamation />
                </IconContainer>
                <div className="flex flex-col">
                  <TitleBox>make sure this new phone is eSIM compatible.</TitleBox>
                  <Link href="https://www.really.com/post/how-to-check-if-your-phone-is-esim-compatible">
                    you can check here
                  </Link>
                </div>
              </TitleContainer>
            </CompatibleContainer>
            <Title>auto renewal</Title>
            <AutoPlayContainer>
              <AutoPlayParagraph>
                by signing up for a plan with REALLY, you agree to automatic plan renewal. your plan
                will renew at the end of each billing cycle (30 days). no annual commitment is
                required. you can cancel at any time.
              </AutoPlayParagraph>
            </AutoPlayContainer>
            <Title>order summary</Title>
            <CheckoutSummary
              paymentData={paymentData}
              loading={paymentLoading}
              selectedPlan={data.selectedPlan || undefined}
            />
            <PromoControl>
              <PromoCodeContainer>
                <Controller
                  name="promocode"
                  control={control}
                  render={(form) => {
                    const { field } = form;
                    const { value, onChange, name } = field;
                    return (
                      <InputField
                        label="have a promo code?"
                        name={name}
                        placeholder=""
                        error={errors.promocode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newValue = e.target.value;
                          // addData({ [name]: newValue });
                          send({ type: "SET_PROMOCODE", promocode: newValue });
                          onChange(newValue);
                          clearErrors(name);
                        }}
                        value={value}
                        className="!bg-[#1A1A1A] !text-white"
                      />
                    );
                  }}
                />
                <Button
                  title="apply"
                  disabled={paymentLoading || !data.promocode}
                  type="active"
                  className="lg:w-[85px] !h-[61px]"
                  css={[errors.promocode && tw`mb-8`]}
                  onClick={() => {
                    clearErrors("promocode");
                    validatePaymentDetails(getValues());
                  }}
                />
              </PromoCodeContainer>
              <Total>
                <TotalRow style={{ marginRight: paymentLoading ? 20 : 0 }}>
                  <TotalSubtitle>subtotal</TotalSubtitle>
                  <TotalSubtitle>
                    {paymentLoading ? (
                      <Spinner />
                    ) : (
                      `${toCurrency(paymentData?.total - paymentData.tax)}`
                    )}
                  </TotalSubtitle>
                </TotalRow>
                <TotalRow style={{ marginRight: paymentLoading ? 20 : 0 }}>
                  <TotalSubtitle>taxes</TotalSubtitle>
                  <TotalSubtitle>
                    {paymentLoading ? <Spinner /> : `${toCurrency(paymentData?.tax)}`}
                  </TotalSubtitle>
                </TotalRow>
                <Separator />
                <TotalRow style={{ marginRight: paymentLoading ? 20 : 0 }}>
                  <TotalTitle>total due</TotalTitle>
                  <TotalTitle>
                    {paymentLoading ? <Spinner /> : `${toCurrency(paymentData?.total)}`}
                  </TotalTitle>
                </TotalRow>
              </Total>
            </PromoControl>
            <Controller
              name="terms"
              control={control}
              rules={{
                required: true,
              }}
              render={(form) => {
                const { field } = form;
                const { onChange, value, name } = field;

                return (
                  <Checkbox
                    name={name}
                    value={value}
                    error={errors.terms}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = e.target.checked;
                      // addData({ terms: newValue });
                      onChange(newValue);
                    }}
                    label={
                      <Label css={[errors.terms && tw`text-red`]}>
                        I agree to REALLY's{" "}
                        <Link href={"https://really.com/terms"} target="_blank">
                          terms and conditions
                        </Link>{" "}
                        and{" "}
                        <Link href={"https://really.com/privacy"} target="_blank">
                          privacy policy
                        </Link>
                      </Label>
                    }
                  />
                );
              }}
            />
            <SubmitBtnContainer>
              <Button
                data-id="checkout"
                title="secure checkout"
                type="active"
                loading={checkoutLoading}
                disabled={checkoutLoading || checkoutDisabled}
                isSubmit
                className="lg:w-full"
                onClick={handleSubmit(onSubmit)}
              />
            </SubmitBtnContainer>
            <ErrorMessage error={unhandledError} />
            <BillingMessage>billing cycle starts only after you activate.</BillingMessage>
          </FlexItem>
        </Container>
      </Form>
    </Content>
  );
};

export default Checkout;
