import { CustomerInfo } from "@hooks/useCustomerInfo";

const DEFAULT_ZIPCODE = "78701";

export const getPrimaryEnrollmentId = (customerInfo: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    console.log("getPrimaryEnrollmentId: customerInfo is not undefined");
    return undefined;
  }

  const primaryLine = customerInfo.lines.find((line) => line.is_primary_enrollment);
  return primaryLine?.enrollment_id ?? customerInfo.lines?.[0]?.enrollment_id;
};

export const getCustomerZipcode = (customerInfo: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    return DEFAULT_ZIPCODE;
  }

  const line = customerInfo.lines.find((line) => line.zip_code);
  return line?.zip_code ?? DEFAULT_ZIPCODE;
};
