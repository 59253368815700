export const extractNumbers: (inputString: string) => string = (inputString) => {
  return inputString.replace(/\D/g, "");
};

export const preloadOnIdle = (importFunc: () => void) => {
  "requestIdleCallback" in window
    ? window.requestIdleCallback(importFunc)
    : setTimeout(importFunc, 1000);
};

export const defaultSteps = [
  { label: "basic info", path: "/basic-info" },
  { label: "purchase plan", path: "/purchase-plan" },
  { label: "create account", path: "/create-account" },
  { label: "install & activate SIM", path: "/install-esim" },
];

export const stepPaths = ["/basic-info", "/privacy", "/create-account", "/purchase-plan"];
