import React, { useEffect, useContext } from "react";
import tw from "twin.macro";
import { useIntercom } from "react-use-intercom";
import { Header } from "@really5g/ui-components";
import { SubscriptionDataContext } from "@context/subscriptionContext";

interface LayoutProps {
  children: React.ReactNode;
  skipIntercom?: boolean;
  darkMode?: boolean;
  goBack?: () => void;
}

const Container = tw.div`min-h-screen bg-white text-black dark:bg-black dark:text-white`;
const Main = tw.main``;

export const SubscriptionLayout: React.FC<LayoutProps> = ({
  children,
  skipIntercom,
  darkMode,
  goBack,
}) => {
  const { boot } = useIntercom();
  const { navigation } = useContext(SubscriptionDataContext);

  useEffect(() => {
    if (skipIntercom) return;
    boot();
  }, [skipIntercom]);

  return (
    <Container>
      <Header
        showBackButton
        backCallback={goBack ?? navigation.goBack}
        darkMode={Boolean(darkMode)}
      />
      <Main>{children}</Main>
    </Container>
  );
};
