import React, { useEffect, useState, useCallback, type ComponentType } from "react";
import { Spinner } from "@components/common/Spinner";
import { getCustomerInfo } from "@api/index";
import { captureException } from "@sentry/react";
import ErrorFallback from "@components/ErrorFallback";
import { Navigate } from "react-router-dom";

const withCustomerInfo = <P extends object>(WrappedComponent: ComponentType<P>): React.FC<P> => {
  return (props: P) => {
    const [customerInfo, setCustomerInfo] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<boolean>();

    const loadCustomerInfo = useCallback(async () => {
      try {
        const customerInfo = await getCustomerInfo();
        setCustomerInfo(customerInfo);
      } catch (error) {
        captureException(error, {
          level: "warning",
          tags: {
            fetchCustomerInfo: true,
          },
        });

        setError(true);
      }
    }, []);

    const fetchPlansAndCustomerInfo = useCallback(async () => {
      await loadCustomerInfo();
      setLoading(false);
    }, []);

    useEffect(() => {
      fetchPlansAndCustomerInfo();
    }, []);

    if (error) return <ErrorFallback />;

    if (loading) {
      return (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      );
    }

    if (window.location.pathname === "/new-line/checkout") {
      return <Navigate to="/new-line/porting" />;
    }

    return <WrappedComponent {...props} customerInfo={customerInfo} />;
  };
};

export default withCustomerInfo;
