const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDateToWord(dateString: string) {
  const date = new Date(dateString);
  const monthName = monthShortNames[date.getUTCMonth()];
  const day = date.getUTCDate();
  return `${monthName} ${day}`;
}

export function formatDateToShortName(dateString: string) {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = monthShortNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}

// Find the first line with a valid expiry date
export function getFirstAvailableExpiryDate(lines: ILine[]) {
  const lineWithExpiryDate = lines.find(
    (line) => line.plan.expiry_date && line.plan.expiry_date !== "NA",
  );
  // put 2024-06-05 08:28:12 temporary for testing
  return lineWithExpiryDate ? lineWithExpiryDate.plan.expiry_date! : "2024-06-05 08:28:12";
}
