import React, { type ReactNode } from "react";
import tw from "twin.macro";
import {
  type FieldError,
  type Merge,
  type FieldErrorsImpl,
  type UseFormRegister,
} from "react-hook-form";
import Check from "@assets/icons/check.svg";

interface InputFieldProps {
  name: string;
  label: ReactNode;
  placeholder?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const InputContainer = tw.div`w-full flex items-center mt-[10px]`;
const Input = tw.input`w-[32px] h-[32px] pl-[16px] absolute opacity-0 cursor-pointer`;
const Label = tw.label`text-[20px] ml-[10px]`;
const CheckContainer = tw.div``;
const CheckedIconContainer = tw.div`w-[32px] h-[32px] flex items-center justify-center bg-white border-2 rounded`;
const CheckedIcon = tw.img`w-[16px] h-[16px]`;

export const Checkbox: React.FC<InputFieldProps> = ({
  label,
  name,
  register,
  required,
  placeholder,
  value,
  onChange,
  error,
}) => {
  return (
    <InputContainer>
      <CheckContainer>
        <Input
          checked={value}
          type="checkbox"
          id={name}
          {...(register ? register(name, { required }) : {})}
          onChange={onChange}
          placeholder={placeholder}
        />
        <CheckedIconContainer className={`${error ? "border-red-500" : "border-black"}`}>
          {value ? <CheckedIcon src={Check} /> : null}
        </CheckedIconContainer>
      </CheckContainer>
      <Label htmlFor={name}>{label}</Label>
    </InputContainer>
  );
};
