import { LogoutIcon } from "@assets/icons";
import { useAuth } from "@context/authContext";
import { NavRoutes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import tw from "twin.macro";

const LogoutButton = tw.button`absolute top-[14px] right-[0px] rounded-3xl w-auto bg-auto bg-inherit`;

const Logout = () => {
  const navigate = useNavigate();
  const { shutdown } = useIntercom();
  const { logout } = useAuth();

  const handleLogout = async () => {
    shutdown();
    await logout();
    navigate(`/${NavRoutes.Login}`);
  };

  return (
    <LogoutButton onClick={handleLogout}>
      <LogoutIcon size={20} />
    </LogoutButton>
  );
};

export default Logout;
