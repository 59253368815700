import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useSidebar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((x) => !x), []);

  // close sidebar on location change (for mobile)
  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return { open, toggle };
};

export default useSidebar;
