import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import { type INavItem } from ".";

const LinkItem = tw(
  NavLink,
)`w-full shrink-0 flex items-center p-4 opacity-60 hover:bg-dark-gray rounded-md `;

interface Props {
  navs: INavItem[];
}

const activeStyle = tw`bg-dark-gray opacity-100`;
const List = tw.ul`md:font-normal md:text-xl md:no-underline font-bold text-4xl underline underline-offset-8 decoration-2`;
const ListItem = tw.li`flex cursor-pointer`;
const IconContainer = tw.div`md:block hidden`;
const NavLabel = tw.span`flex-1 ms-3 whitespace-nowrap text-center md:text-left`;

const Navigation: React.FC<Props> = ({ navs }) => {
  return (
    <List>
      {navs.map((nav) => (
        <ListItem key={nav.label}>
          <LinkItem to={nav.to} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
            <IconContainer>{nav.icon}</IconContainer>
            <NavLabel>{nav.label}</NavLabel>
          </LinkItem>
        </ListItem>
      ))}
    </List>
  );
};

export default Navigation;
