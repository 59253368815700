import logo from "@assets/images/really.png";
import { NavRoutes } from "constants/routes";
import { Link } from "react-router-dom";
type Props = {
  width?: number;
  height?: number;
};
export const ReallyLogo: React.FC<Props> = ({ width, height }) => (
  <Link to={NavRoutes.Dashboard}>
    <img src={logo} alt="Really logo" style={{ width, height }} />
  </Link>
);
